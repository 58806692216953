@import "../styles/load-skeleton-css.scss";
@import "../styles/includes";

:root {
  --text-color: black;
  --bg-color: white;
  //   --foreground-rgb: 0, 0, 0;
  //   --background-start-rgb: 214, 219, 220;
  //   --background-end-rgb: 255, 255, 255;
}

[data-theme="dark"] {
  --text-color: #e3e3e3;
  --bg-color: black;
}

// @media (prefers-color-scheme: dark) {
//   :root {
//     --foreground-rgb: 255, 255, 255;
//     --background-start-rgb: 42, 42, 42;
//     --background-end-rgb: 0, 0, 0;
//   }
// }

html {
  font-size: 16px;
  scroll-behavior: smooth;

  // letter-spacing: 1px;
}

body {
  color: var(--text-color);
  background-color: var(--bg-color);
  // background: linear-gradient(to bottom, transparent, rgb(var(--background-end-rgb)))
  //   rgb(var(--background-start-rgb));
  font-size: 1.25rem;
  letter-spacing: 0.2px;
}

a {
  color: var(--text-color);

  &:hover {
    color: var(--text-color);
  }
}

h1 {
  font-size: 1.75rem;
  font-weight: 500;
  letter-spacing: 0.42px;
  margin-bottom: 0.75rem;
}

strong {
  font-weight: 600;
}

.d-none {
  display: none;
}

.fs-bump {
  font-size: 1.4rem;
}

.mt-1 {
  margin-top: 0.5rem;
}

.mt-5 {
  margin-top: 3rem;
}

.select-none {
  -webkit-user-select: none;
  user-select: none;
}

.label-body {
  @extend .select-none;
}

.heading {
  background-image: linear-gradient(136deg, hsl(32, 98%, 88%), hsl(44, 85%, 82%) 80%);
  padding: 1.6rem 0;

  @include darkMode {
    background-image: linear-gradient(136deg, hsl(166deg, 42%, 38%), hsl(277deg, 34%, 30%));
  }
}

.g-container {
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 2rem;
}

.lead {
  font-weight: 320;
}

.td-none {
  text-decoration: none;
}
